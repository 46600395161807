import * as React from 'react'
import { Drawer as DrawerPrimitive } from 'vaul'
import { cn } from '~/utils/cn'
import { ButtonIcon } from '../button-icon'
import { DrawerCloseButton } from './components/drawer-close-button'
import { DrawerOverlayProps, DrawerContentProps, DrawerHeaderProps } from './drawer-group.types'

const Drawer = ({
  shouldScaleBackground = true,
  direction = 'right',
  ...props
}: React.ComponentProps<typeof DrawerPrimitive.Root>) => (
  <DrawerPrimitive.Root
    shouldScaleBackground={shouldScaleBackground}
    direction={direction}
    {...props}
  />
)
Drawer.displayName = 'Drawer'

const DrawerTrigger = DrawerPrimitive.Trigger

const DrawerPortal = DrawerPrimitive.Portal

const DrawerClose = DrawerPrimitive.Close

const noAnimationProps = {
  'data-vaul-no-animation': 'true',
  style: {
    animation: 'none',
    transition: 'none',
    ['--vaul-drawer-duration' as string]: '0s',
  } as React.CSSProperties,
}

const DrawerOverlay = React.forwardRef<React.ElementRef<typeof DrawerPrimitive.Overlay>, DrawerOverlayProps>(
  ({ className, onOverlayClick, disableAnimation, ...props }, ref) => (
    <DrawerPrimitive.Overlay
      ref={ref}
      onClick={onOverlayClick}
      {...(disableAnimation ? noAnimationProps : {})}
      className={cn('fixed inset-0 z-50 bg-neutral-overlay/50', className)}
      {...props}
    />
  ),
)
DrawerOverlay.displayName = DrawerPrimitive.Overlay?.displayName ?? 'DrawerOverlay'

const DrawerContent = React.forwardRef<React.ElementRef<typeof DrawerPrimitive.Content>, DrawerContentProps>(
  ({ className, children, overlayClassName, expanded, disableAnimation, ...props }, ref) => (
    <DrawerPortal>
      <DrawerOverlay
        className={overlayClassName}
        disableAnimation={disableAnimation}
      />
      <DrawerPrimitive.Content
        ref={ref}
        {...(disableAnimation ? noAnimationProps : {})}
        className={cn(
          'fixed right-0 top-0 z-[70] flex h-screen flex-col justify-start bg-white focus-visible:outline-none',
          {
            'w-full max-w-[35rem]': !expanded,
            'w-full max-w-[100vw]': expanded,
          },
          className,
        )}
        {...props}
      >
        {children}
      </DrawerPrimitive.Content>
    </DrawerPortal>
  ),
)
DrawerContent.displayName = 'DrawerContent'

const DrawerHeader = ({
  className,
  children,
  actions,
  expandable,
  expanded,
  onExpandClick,
  ...props
}: DrawerHeaderProps) => (
  <div
    className={cn('flex items-center justify-between border-b border-bg-neutral-30 bg-neutral-00 p-4', className)}
    {...props}
  >
    <div className='flex items-center gap-3'>
      <DrawerCloseButton />
      <div>{children}</div>
    </div>
    <div className='flex flex-1 items-center justify-end gap-2'>
      {actions}
      {expandable && (
        <ButtonIcon
          icon={expanded ? 'fa-regular fa-compress' : 'fa-regular fa-expand'}
          onClick={onExpandClick}
          aria-label={expanded ? 'Collapse drawer' : 'Expand drawer'}
          size='small'
        />
      )}
    </div>
  </div>
)
DrawerHeader.displayName = 'DrawerHeader'

const DrawerFooter = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn('mt-auto flex gap-2 border-t border-bg-neutral-30 bg-neutral-00 px-4 py-3', className)}
    {...props}
  />
)
DrawerFooter.displayName = 'DrawerFooter'

const DrawerTitle = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DrawerPrimitive.Title
    ref={ref}
    className={cn('text-center text-base font-semibold leading-6 text-typography-high-contrast', className)}
    {...props}
  />
))
DrawerTitle.displayName = DrawerPrimitive.Title?.displayName ?? 'DrawerTitle'

const DrawerDescription = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Description>
>(({ className, ...props }, ref) => (
  <DrawerPrimitive.Description
    ref={ref}
    className={cn('text-center text-sm text-typography-low-contrast', className)}
    {...props}
  />
))
DrawerDescription.displayName = DrawerPrimitive.Description?.displayName ?? 'DrawerDescription'

export {
  Drawer as DrawerGroup,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerPortal,
  DrawerTitle,
  DrawerTrigger,
}
