import { tv } from 'tailwind-variants'

export const buttonIcon = tv({
  base: 'rounded text-icon-neutral-20 enabled:hover:bg-neutral-30 enabled:active:bg-neutral-40 disabled:text-neutral-disabled',
  variants: {
    size: {
      small: 'h-6 w-6',
      medium: 'h-8 w-8',
    },
  },
})
