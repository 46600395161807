import React from 'react'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '~/scalis-components/core/dropdown-menu'
import { cn } from '~/utils/cn'
import { ButtonIcon } from '../../button-icon'
import { Button, ButtonAlign, ButtonKind, ButtonSize } from '../default'
import { ActionButtonProps } from './action-button.types'

export const ActionButton = ({ className, actionsItems, asButtonIcon, buttonProps }: ActionButtonProps) => {
  const { kind, ...rest } = buttonProps || {}
  const button = asButtonIcon ? (
    <ButtonIcon
      icon='fa-solid fa-ellipsis'
      disabled={rest.disabled}
    />
  ) : (
    <Button
      className={cn(
        {
          'w-8 p-1': !buttonProps,
        },
        className,
      )}
      size={ButtonSize.xs}
      kind={(kind as ButtonKind) || ButtonKind.secondary}
      align={ButtonAlign.center}
      {...rest}
      isStandalone={false}
    >
      <i className='fa-solid fa-ellipsis text-icon-neutral-20' />
    </Button>
  )
  return (
    <DropdownMenu modal={false}>
      {rest.disabled ? button : <DropdownMenuTrigger asChild>{button}</DropdownMenuTrigger>}

      <DropdownMenuContent
        align='end'
        className='min-w-[192px] rounded-xl bg-white p-2'
      >
        {actionsItems.map((item) => {
          return (
            <DropdownMenuItem
              key={item.label}
              className='p-2 hover:cursor-pointer hover:bg-background-hover'
              onClick={() => item.onClick()}
            >
              <div className='flex items-center gap-2'>
                <div className='flex h-4 w-4 items-center justify-center'>
                  <i className={cn(item.icon, 'text-neutral-primary')} />
                </div>
                <span className='text-sm font-normal text-neutral-primary'>{item.label}</span>
              </div>
            </DropdownMenuItem>
          )
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
