'use client'

import React, { useState } from 'react'
import { Inter } from 'next/font/google'
import { cn } from '~/utils/cn'
import { Button, ButtonAlign, ButtonKind, ButtonStandaloneKind } from '../button'
import { Caption } from '../typography/components/caption'
import { CaptionColor } from '../typography/components/caption/caption.types'
import { Title } from '../typography/components/title'
import { TitleSize } from '../typography/components/title/title.types'
import { DrawerClose, DrawerContent, DrawerFooter, DrawerGroup, DrawerHeader, DrawerTitle } from './drawer-group'
import { DEFAULT_PROPS_CANCEL_BUTTON } from './drawer.constants'
import { DrawerProps } from './drawer.types'

const inter = Inter({ subsets: ['latin'] })

export const Drawer = ({
  isOpen,
  onOpenChange,
  children,
  actions,
  title,
  subtitle,
  successButtonProps,
  standaloneButtonProps,
  secondaryButtonProps,
  fullWidth,
  cancelButtonProps = DEFAULT_PROPS_CANCEL_BUTTON,
  ariaDescribedBy = '',
  onClose,
  contentClassName,
  overlayClassName,
  footerContent,
  hideFooterContent = false,
  endAlignedFooterActions,
  expandable,
  className,
  style,
  onAnimationEnd,
}: DrawerProps) => {
  const [expanded, setExpanded] = useState(false)
  const { label, hidden: hideSuccessButton, ...successButton } = successButtonProps ?? {}
  const { hidden: hideSecondaryButton } = secondaryButtonProps ?? {}
  const { hidden: hideCancelButton } = cancelButtonProps ?? {}

  const hasSuccessButton = !hideSuccessButton && !!successButtonProps
  const hasSecondaryButton = !hideSecondaryButton && !!secondaryButtonProps
  const hasCancelButton = !hideCancelButton && !!cancelButtonProps

  const buttonsToShow = [hasSuccessButton, hasSecondaryButton, hasCancelButton].filter(Boolean).length

  return (
    <DrawerGroup
      open={isOpen}
      onOpenChange={(value) => {
        onOpenChange?.(value)

        if (!value) {
          onClose?.()
        }
      }}
      onAnimationEnd={onAnimationEnd}
    >
      <DrawerContent
        asChild
        className={cn(inter.className, className, fullWidth && 'w-full max-w-full')}
        style={style}
        aria-describedby={ariaDescribedBy ?? 'Drawer Content'}
        expanded={expanded}
        overlayClassName={overlayClassName}
      >
        <div data-vaul-no-drag>
          <DrawerHeader
            actions={actions}
            expandable={expandable}
            expanded={expanded}
            onExpandClick={() => setExpanded(!expanded)}
          >
            <DrawerTitle asChild>
              <div className='text-center text-base font-semibold leading-6 text-typography-high-contrast'>
                <div
                  data-testid='drawer-heading'
                  className='flex flex-col gap-1'
                >
                  <Title
                    size={TitleSize.small}
                    className='text-[18px] font-medium leading-6 tracking-[-0.8px] text-[#1F2124]'
                    asChild
                  >
                    <span>{title ?? ''}</span>
                  </Title>

                  {subtitle && <Caption color={CaptionColor.secondary}>{subtitle}</Caption>}
                </div>
              </div>
            </DrawerTitle>
          </DrawerHeader>

          <div className={cn('h-screen overflow-y-auto p-5', contentClassName)}>{children}</div>

          {!hideFooterContent && (
            <DrawerFooter className='flex justify-between'>
              {footerContent ? (
                <div className='flex w-full'>{footerContent}</div>
              ) : (
                <>
                  {standaloneButtonProps != null && (
                    <div>
                      <Button
                        {...standaloneButtonProps}
                        isStandalone={true}
                        kind={ButtonStandaloneKind.brand}
                      >
                        {standaloneButtonProps.label}
                      </Button>
                    </div>
                  )}

                  <div
                    className={cn('grid w-full grid-cols-2 gap-2', {
                      'grid-cols-3': buttonsToShow === 3,
                      'flex flex-row justify-end': endAlignedFooterActions,
                      'flex flex-row items-end': standaloneButtonProps != null,
                    })}
                  >
                    <DrawerClose asChild>
                      {hasCancelButton && (
                        <Button
                          {...cancelButtonProps}
                          isStandalone={false}
                          kind={ButtonKind.secondary}
                          align={ButtonAlign.center}
                        >
                          {cancelButtonProps.label}
                        </Button>
                      )}
                    </DrawerClose>
                    {hasSecondaryButton && (
                      <Button
                        {...secondaryButtonProps}
                        isStandalone={false}
                        kind={ButtonKind.secondary}
                        align={ButtonAlign.center}
                      >
                        {secondaryButtonProps.label}
                      </Button>
                    )}
                    {hasSuccessButton && (
                      <Button
                        align={ButtonAlign.center}
                        {...successButton}
                      >
                        {label}
                      </Button>
                    )}
                  </div>
                </>
              )}
            </DrawerFooter>
          )}
        </div>
      </DrawerContent>
    </DrawerGroup>
  )
}
